import { Group, Stack, Text, UnstyledButton } from '@mantine/core'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import OnBoardingLayout from '../../layouts/OnboardingLayout'
import { useAuthStore } from '../../stores/authStore'
import { useClinicsStore } from '../../stores/clinicsStore'
import { useUsersStore } from '../../stores/usersStore'
import SignupEmailSent from './SignupEmalSent'
import StepFour, { StepFourForm } from './StepFour'
import StepOne, { StepOneForm } from './StepOne'
import StepThree, { StepThreeForm } from './StepThree'
import StepTwo, { StepTwoForm } from './StepTwo'

interface SignupUserForm {
  firstName: string
  lastName: string
  phoneNumber: ''
  email: string
  clinicTypeId: number
  password: string
  address: string
  plan: 'pro' | 'free'
}

interface SignupClinicForm {
  name: ''
  logo: ''
  phoneNumber: ''
  clinicTypeId: number
}

export type { SignupUserForm, SignupClinicForm }

function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

function Signup() {
    const [currentStep, setStep] = useState(1)
    const [signupEmail, setSignupEmail] = useState('')
    const [doctorData, setDoctorData] = useState<StepThreeForm>()
    const authStore = useAuthStore()
    const clinics = useClinicsStore()
    const users = useUsersStore()
    const queryParams = useQuery()
    const navigate = useNavigate()

    const token = useMemo(() => queryParams.get('token'), [queryParams])
    const isVerified = useMemo(() => authStore.user?.isVerified ?? false, [authStore.user])

    useEffect(() => {
        if (token == null) return

        void authStore.controls.verify(token).then(() => setStep(2))
    }, [token, authStore.controls])

    function pressBack() {
        if (currentStep > 1) setStep(currentStep - 1)
        else window.history.back()
    }

    function completeFormOne(data: StepOneForm) {
        setSignupEmail(data.email)
        void authStore.controls.signup(data.email, data.password).then(() => setStep(2))
    }

    function completeFormTwo(data: StepTwoForm) {
        clinics.controls.createClinic(
            data.cabinetName,
            data.type,
            data.phoneNumber,
            data.address
        )
        setStep(3)
    }

    function completeFormThree(data: StepThreeForm) {
        setDoctorData(data)
        void users.controls.patchCurrentUser({
            namePrefix: data.namePrefix,
            firstName: data.firstName,
            lastName: data.lastName,
            speciality: data.speciality,
            description: data.description
        }).then(() => setStep(4))
    }

    function completeFormFour(data: StepFourForm) {
        void users.controls.patchCurrentUser({
            tariff: data.tariff
        }).then(() => navigate('/statistiques'))
    }

    let content: ReactNode
    switch (currentStep) {
        case 1:{
            content = <StepOne
                submit={completeFormOne}
                email={signupEmail} />
            break
        }
        case 2:{
            content = isVerified
                ? <StepTwo
                    submit={completeFormTwo}
                    value={{}}
                    saveInProgress={clinics.saveInProgress} />
                : <SignupEmailSent email={signupEmail} />
            break
        }
        case 3:{
            content = <StepThree
                submit={completeFormThree}
                value={(doctorData != null) ? doctorData : {}}
                saveInProgress={users.saveInProgress} />
            break
        }
        case 4:{
            content = <StepFour
                submit={completeFormFour}
                saveInProgress={users.saveInProgress} />
            break
        }
    }

    return (
        <OnBoardingLayout>
            <Stack justify={'start'}
                style={{ height: '100%' }}>
                <Group position="apart">
                    <UnstyledButton onClick={pressBack}
                        color="dimmed">
                        <Group>
                            <FaChevronLeft color={'#868e96'} />
                            <Text color="dimmed">Retour</Text>
                        </Group>
                    </UnstyledButton>
                    <p><b>Étape {String(currentStep).padStart(2, '0')}/04</b></p>
                </Group>
                {content}
            </Stack>
        </OnBoardingLayout>
    )
}
export default Signup
