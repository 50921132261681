import {
    Button, createStyles, Group, LoadingOverlay, Stack, TextInput
} from '@mantine/core'
import { useForm } from '@mantine/form'
import React from 'react'
import { FaCheckCircle, FaChevronRight } from 'react-icons/fa'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

interface StepTwoProps {
  submit: (data: StepTwoForm) => void
  saveInProgress: boolean
  value: Partial<StepTwoForm>
}

interface StepTwoForm {
  cabinetName: string
  type: string
  phoneNumber: string
  address: string
  location: string
}

export type { StepTwoForm }

const useStyles = createStyles((theme) => ({
    phoneInput: {
        border: '1px solid transparent',
        backgroundColor: '#f1f3f5',
        fontFamily: 'Poppins',
        height: '50px',
        WebkitTapHighlightColor: 'transparent',
        lineHeight: '48px',
        appearance: 'none',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '18px',
        width: '100%',
        color: '#000',
        display: 'block',
        textAlign: 'left',
        minHeight: '50px',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderRadius: '16px'
    }
}))

const AppTextField = React.forwardRef<HTMLInputElement>(function AppTextField(props, ref) {
    return (
        <TextInput
            {...props}
            required
            radius='lg'
            ref={ref}
            size='lg'
            variant='filled'
            classNames={{ input: 'customInput' }}
        ></TextInput>
    )
})

function StepTwo({ submit, value, saveInProgress }: StepTwoProps) {
    const form = useForm<StepTwoForm>({
        initialValues: {
            cabinetName: value.cabinetName ?? '',
            type: value.type ?? '',
            phoneNumber: value.phoneNumber ?? '+213',
            address: value.address ?? '',
            location: value.location ?? ''
        }
    })

    const { classes } = useStyles()

    return (
        <>
            <LoadingOverlay visible={saveInProgress}
                radius={30}
                loaderProps={{ color: 'yamnaPink' }} />

            <Group> <FaCheckCircle color="#1BBBF6"/><h4> Your email has been confirmed successfully</h4> </Group>
            <h2>Cabinet info</h2>
            <form onSubmit={form.onSubmit((values) => submit(values))}
                style={{ height: '100%' }}>
                <Stack justify={'space-between'}
                    style={{ height: '100%' }}>
                    <Stack align={'stretch'}>
                        <TextInput
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Cabinet name"
                            {...form.getInputProps('cabinetName')}
                            classNames={{ input: 'customInput' }} />

                        <TextInput required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Type"
                            {...form.getInputProps('type')}
                            classNames={{ input: 'customInput' }} />

                        <PhoneInput className={classes.phoneInput}
                            countrySelectProps={{ unicodeFlags: true }}
                            international
                            defaultCountry="DZ"
                            {...form.getInputProps('phoneNumber')}
                            inputComponent={AppTextField}></PhoneInput>

                        <TextInput required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Address"
                            {...form.getInputProps('address')}
                            classNames={{ input: 'customInput' }} />
                    </Stack>

                    <Button
                        type='submit'
                        fullWidth
                        radius="md"
                        size="lg"
                        rightIcon={<FaChevronRight/>}
                        styles={() => ({
                            inner: {
                                justifyContent: 'space-between'
                            }
                        })}
                        my={'md'}
                    >
                        Next
                    </Button>
                </Stack>
            </form>
        </>
    )
}

export default StepTwo
