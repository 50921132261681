import create from 'zustand'
import axiosClient from '../api/ApiClient'
import Clinic from '../models/clinic'

interface ClinicsStore {
  currentClinic: Clinic | null
  saveInProgress: boolean
  controls: {
    createClinic: (clinicName: string, clinicType: string, phoneNumber: string, address: string) => void
  }
}

const useClinicsStore = create<ClinicsStore>((set) => ({
    currentClinic: null,
    saveInProgress: false,
    controls: {
        createClinic: (clinicName: string, clinicType: string, phoneNumber: string, address: string) => {
            set(() => ({
                saveInProgress: true
            }))
            void axiosClient.post('/clinic', {
                name: clinicName,
                type: clinicType,
                phoneNumber,
                address
            }).then(response => response.data).then(clinic => {
                set(() => ({
                    currentClinic: clinic,
                    saveInProgress: false
                }))
            })
        }
    }
}))

export { useClinicsStore }
