import { Card, Group, ScrollArea, Stack, Text } from '@mantine/core'
import React, { ReactNode } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import NextButton from './NextButton'

interface TariffPlanProps {
    title: string
    features: string[]
    subtitle: ReactNode
    selected: boolean
    onSelectPlan?: () => void
}

interface FeatureProps {
    description: string
}

function Feature(props: FeatureProps) {
    return <Group noWrap align={'top'}>
        <FaCheckCircle style={{ flexShrink: 0 }}></FaCheckCircle>
        <Text style={{ flexGrow: 11 }}>{ props.description }</Text>
    </Group>
}

export default function TariffPlan(tariffPlan: TariffPlanProps) {
    const features = tariffPlan.features
        .map(
            (feature) => <Feature description={feature} key={feature}></Feature>
        )

    return <Card
        p='lg'
        style={{
            backgroundColor: tariffPlan.selected
                ? 'rgba(27, 187, 246, 0.1)'
                : '#F4F5F5',
            height: '55vh',
            cursor: 'pointer',
            userSelect: 'none',
            border: tariffPlan.selected ? '2px solid #1BBBF6' : 'none'
        }}
        radius={'lg'}>
        <Stack justify={'space-between'} style={{
            height: '100%'
        }}
        onClick={tariffPlan.onSelectPlan}>
            <Card.Section px={'md'}>
                <h2>{tariffPlan.title}</h2>
                {tariffPlan.subtitle}
            </Card.Section>

            <ScrollArea type='hover' style={{ flexShrink: 1, flexGrow: 1 }}>
                <Stack>
                    {features}
                </Stack>
            </ScrollArea>

            {tariffPlan.selected &&
                <NextButton style={{ justifySelf: 'flex-end', flexShrink: 0 }} label='Choisir'></NextButton>
            }
        </Stack>
    </Card>
}
