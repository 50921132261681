import { Button } from '@mantine/core'
import React from 'react'
import { Link, To } from 'react-router-dom'

interface HeaderButtonProps {
    icon: React.ReactNode
    content: React.ReactNode
    to: To
    color: string
    active?: boolean
    shown?: boolean
}

export default function HeaderButton({
    icon,
    content,
    to,
    color,
    active = false,
    shown = true
}: HeaderButtonProps) {
    if (!shown) return <></>

    return (
        <Button leftIcon={icon} component={Link}
            to={to} variant="subtle"
            color="white" size="md"
            styles={(theme) => ({
                root: {
                    height: 48,
                    borderRadius: 9,
                    '&:hover': {
                        backgroundColor: color,
                        color: 'white'
                    },
                    '&': active
                        ? {
                            backgroundColor: color,
                            color: 'white'
                        }
                        : {}
                }
            })}>
            {content}
        </Button>
    )
}
