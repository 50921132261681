import { createStyles, Stack, Text } from '@mantine/core'
import { Square } from 'react-feather'

interface StepOneProps {
    email: string
}

const useStyles = createStyles((theme) => ({
    heading: {
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 600,
        fontFamily: 'Poppins'
    },
    message: {
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        fontWeight: 400,
        fontFamily: 'Poppins'
    }
}))

function SignupEmailSent(props: StepOneProps) {
    const { classes } = useStyles()
    return (
        <>
            <form
                onSubmit={() => {}}
                style={{ height: '100%' }}>

                <Stack
                    justify={'space-between'}
                    style={{ height: '100%' }}>

                    <Stack align={'center'}>
                        <Square size="md"
                            width={256}
                            fill='red'
                            color='red'/>
                        <Text className={classes.heading}>An email has been sent</Text>
                        <Text className={classes.message}>
                            We’ve sent a confirmation email to <br/>
                            <strong>{props.email}</strong> <br/>
                            Please check your inbox and click on the link to continue.
                        </Text>
                    </Stack>
                </Stack>
            </form>
        </>
    )
}

export default SignupEmailSent
