import create from 'zustand'
import axiosClient from '../api/ApiClient'
import User from '../models/user'

interface UsersStore {
  saveInProgress: boolean
  isFindPending: boolean
  users: User[]
  controls: {
    patchCurrentUser: (data: {
        namePrefix?: string
        firstName?: string
        lastName?: string
        speciality?: string
        description?: string
        tariff?: 'BASIC' | 'PRO'
    }) => Promise<void>
    fetchUsers: () => Promise<void>
    fetchAvailableUsers: (date: string) => Promise<void>
  }
}

const useUsersStore = create<UsersStore>((set) => ({
    saveInProgress: false,
    isFindPending: false,
    users: [],
    controls: {
        patchCurrentUser: async ({
            namePrefix,
            firstName,
            lastName,
            speciality,
            description,
            tariff
        }) => {
            set(() => ({
                saveInProgress: true
            }))
            return await axiosClient.patch('/user/current', {
                namePrefix,
                firstName,
                lastName,
                speciality,
                description,
                tariff
            }).then(response => response.data).finally(() => {
                set(() => ({
                    saveInProgress: false
                }))
            })
        },
        fetchUsers: async () => {
            set(() => ({
                isFindPending: true
            }))
            return await axiosClient.get('/user/').then(response => response.data).then((data) => {
                set(() => ({
                    users: data
                }))
            }).finally(() => {
                set(() => ({
                    isFindPending: false
                }))
            })
        },
        fetchAvailableUsers: async (date) => {
            set(() => ({
                isFindPending: true
            }))
            return await axiosClient.get(`/user/getAvailable?datetime=${encodeURIComponent(date)}`)
                .then(response => response.data).then((data) => {
                    set({ users: data })
                }).finally(() => {
                    set({ isFindPending: false })
                })
        }
    }
}))

export { useUsersStore }
