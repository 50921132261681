import create from 'zustand'
import { persist } from 'zustand/middleware'
import User from '../models/user'
import client from '../api/ApiClient'

interface TokenStore {
  token: string
  storeToken: (token: string) => void
}

const useTokenStore = create(
    persist<TokenStore>(
        (set) => ({
            token: '',
            storeToken: (token: string) => set({ token })
        }),
        { name: 'token' }
    )
)

interface AuthStore {
  user: User | null
  authInProgress: boolean
  loggedIn: boolean
  errorMessage: string | null
  controls: {
    storeUser: (user: User) => void
    login: (email: string, password: string) => Promise<void>
    signup: (email: string, password: string) => Promise<void>
    loadCurrentUser: (force?: boolean) => Promise<void>
    logout: () => Promise<void>
    verify: (token: string) => Promise<void>
  }
}

const useAuthStore = create<AuthStore>((set, get) => ({
    user: null,
    authInProgress: false,
    errorMessage: null,
    loggedIn: false,
    controls: {
        storeUser: (user: User) => set(() => ({ user, authInProgress: false, loggedIn: true })),

        login: async (email, password) => {
            set(() => ({ errorMessage: null }))
            set(() => ({ authInProgress: true }))

            await client
                .post('/auth/login', {
                    email,
                    password
                })
                .then((response) => {
                    const { storeToken } = useTokenStore.getState()
                    const { access_token: accessToken, user } = response.data
                    storeToken(accessToken)

                    get().controls.storeUser(user)

                    set(() => ({ loggedIn: true }))
                })
                .catch((error) => {
                    set(() => ({
                        errorMessage: error.errorMessage
                    }))
                })
                .finally(() => set(() => ({ authInProgress: false })))
        },

        loadCurrentUser: async (force: boolean = false) => {
            await client
                .get('/auth/currentUser')
                .then((response) => {
                    const user = response.data

                    get().controls.storeUser(user)
                })
                .catch((error) => {
                    set(() => ({
                        errorMessage: error.errorMessage
                    }))
                })
                .finally(() => set(() => ({ authInProgress: false })))
        },

        logout: async () => {
            const { storeToken } = useTokenStore.getState()
            storeToken('')

            set(() => ({
                errorMessage: null,
                user: null,
                authInProgress: false,
                loggedIn: false
            }))
        },

        signup: async (email: string, password: string) => {
            await client.post('/auth/signup', { email, password })
        },

        verify: async (token: string) => {
            await client.post('/auth/verify', { token }).then((response) => {
                const { storeToken } = useTokenStore.getState()
                const { user, access_token: accessToken } = response.data

                get().controls.storeUser(user)
                storeToken(accessToken)
            })
        }
    }
}))

export { useTokenStore, useAuthStore }
