import { Badge, Group, LoadingOverlay, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import TariffPlan from '../../components/TariffPlan'

interface StepFourProps {
  submit: (data: StepFourForm) => void
  saveInProgress: boolean
}

interface StepFourForm {
  tariff: 'BASIC' | 'PRO'
}

const plansFeatures: { [plan in StepFourForm['tariff']]: string[] } = {
    BASIC: [
        "Gestion des patients jusqu'à 10 Profiles",
        "Gestion des files d'attente jusqu'à 50 réservations par mois"
    ],
    PRO: [
        'Page web publique avec référencement',
        'Gestion illimitée du Profile des patients',
        "Gestion illimitée des files d'attente",
        'Suivi budgétaire',
        'Gestion des rôles',
        'Accès à de multiples Profiles',
        'Tableau de bord des Statistics',
        'Assistance 7/7'
    ]
}

export type { StepFourForm }

function StepFour({ submit, saveInProgress }: StepFourProps) {
    const form = useForm<StepFourForm>({
        initialValues: {
            tariff: 'PRO'
        }
    })

    return (
        <>
            <LoadingOverlay
                visible={saveInProgress}
                radius={30}
                loaderProps={{ color: 'yamnaPink' }}
            />
            <h2>Comment souhaitez-vous <br></br> utiliser Yamna ?</h2>
            <form
                onSubmit={form.onSubmit(submit)}
                style={{ height: '100%' }}
            >
                <Stack
                    justify={'space-between'}
                    style={{ height: '100%' }}
                >
                    <Group align={'stretch'}>
                        <div style={{ height: '100%', width: '48%' }}>
                            <TariffPlan
                                title='Basic Plan'
                                features={plansFeatures.BASIC}
                                subtitle={
                                    <>
                                        <span style={{ color: '#1BBBF6' }}>
                                            <b>Gratuit</b> pour toujours
                                        </span>
                                    </>
                                }
                                selected={form.values.tariff === 'BASIC'}
                                onSelectPlan={() => { form.setValues({ tariff: 'BASIC' }) }}/>
                        </div>
                        <div style={{ height: '100%', width: '48%' }}>
                            <TariffPlan
                                title='Pro Plan'
                                subtitle={
                                    <Stack align={'start'}>
                                        <span style={{ color: '#1BBBF6' }}>
                                            <b>4900DA</b>/mois
                                        </span>
                                        <Badge color="orange"
                                            size="xl"
                                            radius="md"
                                            variant="outline"
                                            px={'md'}>
                                            1 week free trial
                                        </Badge>
                                    </Stack>
                                }
                                features={plansFeatures.PRO}
                                selected={form.values.tariff === 'PRO'}
                                onSelectPlan={() => { form.setValues({ tariff: 'PRO' }) }}/>
                        </div>
                    </Group>
                </Stack>
            </form>
        </>
    )
}

export default StepFour
