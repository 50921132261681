import { Footer } from '@mantine/core'
import moment from 'moment'

export default function AppFooter() {
    return (
        <Footer
            height={'1em'}
            m={'xl'}
            style={{
                backgroundColor: 'transparent'
            }}
            withBorder={false}
        >
            <p>© Copyright {moment().format('YYYY')} • Doctomeet • Terms & Services • Privacy policy</p>
        </Footer>
    )
};
