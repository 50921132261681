import { Button, DefaultProps } from '@mantine/core'
import { FaChevronRight } from 'react-icons/fa'

interface NextButtonPros extends DefaultProps {
  label?: string
}

export default function NextButton(props: NextButtonPros = { label: 'Next' }) {
    return (
        <>
            <Button
                type="submit"
                fullWidth
                radius="md"
                size="lg"
                rightIcon={<FaChevronRight />}
                styles={() => ({
                    inner: {
                        justifyContent: 'space-between'
                    }
                })}
                style={props.style}
                my={'md'}
            >
                {props.label ?? 'Next'}
            </Button>
        </>
    )
}
