import { LoadingOverlay, Select, Stack, Textarea, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import NextButton from '../../components/NextButton'

interface StepThreeProps {
    submit: (data: StepThreeForm) => void
    value: Partial<StepThreeForm>
    saveInProgress: boolean
}

interface StepThreeForm {
    namePrefix: 'Dr.'
    firstName: string
    lastName: string
    speciality: string
    description: string
}

export type { StepThreeForm }

function StepThree({ submit, value, saveInProgress }: StepThreeProps) {
    const form = useForm<StepThreeForm>({
        initialValues: {
            namePrefix: 'Dr.',
            firstName: value.firstName ?? '',
            lastName: value.lastName ?? '',
            speciality: value.speciality ?? '',
            description: value.description ?? ''
        }
    })

    return (
        <>
            <LoadingOverlay visible={saveInProgress}
                radius={30}
                loaderProps={{ color: 'yamnaPink' }} />
            <h2>Doctor Info</h2>
            <form onSubmit={form.onSubmit(submit)}
                style={{ height: '100%' }}>

                <Stack
                    justify={'space-between'}
                    style={{ height: '100%' }}>

                    <Stack align={'stretch'}>
                        <Select
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Prefix"
                            {...form.getInputProps('namePrefix')}
                            classNames={{ input: 'customInput' }}
                            data={[
                                'Dr.', 'Pr.'
                            ]}
                            style={{ maxWidth: 128 }}
                        />

                        <TextInput
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Last name"
                            {...form.getInputProps('lastName')}
                            classNames={{ input: 'customInput' }}
                        />

                        <TextInput
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="First name"
                            {...form.getInputProps('firstName')}
                            classNames={{ input: 'customInput' }}
                        />

                        <TextInput
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Speciality"
                            {...form.getInputProps('speciality')}
                            classNames={{ input: 'customInput' }}
                        />

                        <Textarea
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Description"
                            {...form.getInputProps('description')}
                            classNames={{ input: 'customInput' }}
                            rows={4}
                            autosize
                            minRows={4}
                        />
                    </Stack>

                    <NextButton />

                </Stack>
            </form>
        </>
    )
}

export default StepThree
