import { Button, Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FaChevronRight } from 'react-icons/fa'

interface StepOneProps {
    submit: (user: StepOneForm) => void
    email: string
}

interface StepOneForm {
    email: string
    password: string
    confirmPassword: string
}

export type { StepOneForm }

function StepOne(props: StepOneProps) {
    const form = useForm<StepOneForm>({
        initialValues: {
            email: props.email,
            password: '',
            confirmPassword: ''
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value) => (value.length < 6 ? 'Password length' : null)
        }
    })

    function submitLogin(params: StepOneForm) {
        if (params.confirmPassword !== params.password) return // TODO: show message
        if (params.password.length === 0) return // TODO: show password validate
        props.submit(params)
    }

    return (
        <>
            <h2>Creer votre compte</h2>
            <form
                onSubmit={form.onSubmit((values) => submitLogin(values))}
                style={{ height: '100%' }}>

                <Stack
                    justify={'space-between'}
                    style={{ height: '100%' }}>

                    <Stack align={'stretch'}>
                        <TextInput
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Adresse e-mail"
                            {...form.getInputProps('email')}
                            classNames={{ input: 'customInput' }}
                        />

                        <TextInput
                            autoComplete="new-password"
                            type="password"
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Entrez votre mot de passe"
                            {...form.getInputProps('password')}
                            classNames={{ input: 'customInput' }}
                            minLength={6}
                        />

                        <TextInput
                            autoComplete="new-password"
                            type="password"
                            required
                            size="lg"
                            radius="lg"
                            variant="filled"
                            placeholder="Confirmez votre mot de passe"
                            {...form.getInputProps('confirmPassword')}
                            classNames={{ input: 'customInput' }}
                        />
                    </Stack>

                    <Button
                        type='submit'
                        fullWidth
                        radius="md"
                        size="lg"
                        rightIcon={<FaChevronRight/>}
                        styles={() => ({
                            inner: {
                                justifyContent: 'space-between'
                            }
                        })}
                        my={'md'}
                    >Next</Button>
                </Stack>
            </form>
        </>
    )
}

export default StepOne
