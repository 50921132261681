import axios from 'axios'
import { useTokenStore } from '../stores/authStore'

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})
axiosClient.interceptors.request.use(
    (config) => {
        const { token } = useTokenStore.getState()
        if (token) {
            if (!config.headers) config.headers = {}
            config.headers.Authorization = 'Bearer ' + token
        }
        return config
    },
    async (error) => {
        return await Promise.reject(error)
    }
)

export { axiosClient }
export default axiosClient
